import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";

const SideBar = (props) => {
  return (
    <Container isOpen={props.isOpen}>
      <Icon onClick={props.toggle}>
        <CloseIcon />
      </Icon>
      <SideBarWrapper>
        <SideBarMenu>
          <SideBarLink to="about" onClick={props.toggle}>
            About
          </SideBarLink>
          <SideBarLink to="services" onClick={props.toggle}>
            Service
          </SideBarLink>
          <SideBarLink to="discover" onClick={props.toggle}>
            Discover
          </SideBarLink>
          <SideBarLink to="signup" onClick={props.toggle}>
            Sign Up
          </SideBarLink>
        </SideBarMenu>
        <SideBtnWrap>
          <SideBarRoute to="/signin" onClick={props.toggle}>
            Sign In
          </SideBarRoute>
        </SideBtnWrap>
      </SideBarWrapper>
    </Container>
  );
};

const Container = styled.aside`
  width: 100%;
  height: 100%;
  background-color: #0d0d0d;
  position: fixed;
  z-index: 5;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.5s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-120%")};
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background-color: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;
const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

const SideBarWrapper = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
`;
const SideBarMenu = styled.ul`
  color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

const SideBarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;

const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const SideBarRoute = styled(LinkR)`
  border-radius: 50px;
  background-color: #01bf71;
  white-space: nowrap;
  padding: 16px 64px;
  margin-right: -45px;
  color: #010606;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    color: #010606;
  }
`;

export default SideBar;
