import React from "react";
import { Link as LinkR } from "react-router-dom";
import styled from "styled-components";
import {
  FaFacebook,
  FaTiktok,
  FaInstagram,
  FaYoutube,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  return (
    <Container>
      <Wrap>
        <LinksContainer>
          <LinksWrapper>
            <LinkItems>
              <LinkTitle> About Us</LinkTitle>
              <Link to="/">How it works</Link>
              <Link to="/">Testimonials</Link>
              <Link to="/">Careers</Link>
              <Link to="/">Investors</Link>
              <Link to="/">Terms of Services</Link>
            </LinkItems>
            <LinkItems>
              <LinkTitle> Contact Us</LinkTitle>
              <Link to="/">Contact</Link>
              <Link to="/">Support</Link>
              <Link to="/">Destinations</Link>
              <Link to="/">Sponsorships</Link>
            </LinkItems>
          </LinksWrapper>
          <LinksWrapper>
            <LinkItems>
              <LinkTitle>Videos</LinkTitle>
              <Link to="/">Submit Video</Link>
              <Link to="/">Ambassadors</Link>
              <Link to="/">Agency</Link>
              <Link to="/">Influencer</Link>
            </LinkItems>
            <LinkItems>
              <LinkTitle>Social Media</LinkTitle>
              <Link to="/signin">Instagram</Link>
              <Link to="/signin">Facebook</Link>
              <Link to="/signin">Youtube</Link>
              <Link to="/signin">Twitter</Link>
            </LinkItems>
          </LinksWrapper>
        </LinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">Dampa</SocialLogo>
            <WebsiteRight>
              © Dampa {new Date().getFullYear()}. All Rights Reserved.
            </WebsiteRight>
            <SocialIcons>
              <SocialIconLink
                href="//www.facebook.com/"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="//www.instagram.com/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="//www.tiktok.com/"
                target="_blank"
                aria-label="Tiktok"
              >
                <FaTiktok />
              </SocialIconLink>
              <SocialIconLink
                href="//www.twitter.com/"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href="//www.youtube.com/"
                target="_blank"
                aria-label="Youtube"
              >
                <FaYoutube />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </Wrap>
    </Container>
  );
};

const Container = styled.footer`
  background-color: #101522;
`;
const Wrap = styled.div`
  padding: 48px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;
const LinksContainer = styled.div`
  display: flex;

  justify-content: center;
  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;
const LinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

const LinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 720px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

const LinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;
const Link = styled(LinkR)`
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 0.5rem;

  &:hover {
    color: #01bf71;
    transition: 0.1s ease-in-out;
    text-decoration: underline;
  }
`;
const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;
const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;
  flex-direction: column;
`;
const SocialLogo = styled(LinkR)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 700;
`;
const WebsiteRight = styled.small`
  color: #fff;
  margin-bottom: 16px;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;
const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export default Footer;
