export const title = "Virtual Banking Made Easy";
export const paragraph =
  "Sign up for a new account today and receive $250 in credit towards your next payment.";
export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: "Premium Bank",
  headline: "Unlimited Transactions with zero fees",
  description:
    "Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.",
  buttonLabel: "Get started",
  imgStart: false,
  img: "/images/car.svg",
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Unlimited Access",
  headline: "Login to your account at any time",
  description:
    "We have you covered no matter where you are located. All you need is an internet connection and phone or computer.",
  buttonLabel: "Learn More",
  imgStart: true,
  img: "/images/savings.svg",
  alt: "PigBank",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjFour = {
  id: "signup",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Join our Team",
  headline: "Creating an account is extremely easy",
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Start now",
  imgStart: false,
  img: "/images/analytics.svg",
  alt: "Analytics",
  dark: false,
  primary: false,
  darkText: true,
};
export const homeObjThree = {
  id: "services",
  Icon1: "/images/success.svg",
  Icon2: "/images/world.svg",
  Icon3: "/images/savings.svg",
};
