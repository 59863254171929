import React from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Hero from "./Hero";
import Info from "./Info";
import { homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from "../Data";
import Services from "./Services";
import Footer from "./Footer";

const Home = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    console.log(isOpen);
  };

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <Hero />
      <Info {...homeObjOne} />
      <Info {...homeObjTwo} />
      <Services {...homeObjThree} />
      <Info {...homeObjFour} />
      <Footer />
    </>
  );
};

export default Home;
