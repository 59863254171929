import React from "react";
import styled from "styled-components";
import { title, paragraph } from "../Data";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";
const Hero = () => {
  const [hover, setHover] = React.useState(true);
  const onHover = () => setHover((prevHover) => !prevHover);

  return (
    <Container>
      <Background>
        <VideoBackground
          autoPlay
          loop
          muted
          src="/videos/background_video.mp4"
          type="/video/mp4"
        />
      </Background>
      <Content>
        <h1>{title}</h1>
        <p>{paragraph}</p>
        <WrapperBtn>
          <Button
            to="signup"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            Get started
            {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </WrapperBtn>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background-color: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
    z-index: 2;
  }
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const VideoBackground = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #232a34;
`;
const Content = styled.div`
  z-index: 1;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #fff;
    font-size: 48px;
    text-align: center;

    @media screen and (max-with: 768px) {
      font-size: 40px;
    }
    @media screen and (max-with: 480px) {
      font-size: 32px;
    }
  }

  p {
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-with: 768px) {
      font-size: 24px;
    }
    @media screen and (max-with: 480px) {
      font-size: 18px;
    }
  }
`;

const WrapperBtn = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#01BF71" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover,
  :active {
    transition: all 0.2x ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "#01BF71")};
  }
`;

const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export default Hero;
