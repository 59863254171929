import React from "react";
import styled from "styled-components";

const Services = ({ Icon1, alt1, Icon2, alt2, Icon3, alt3 }) => {
  return (
    <Container id="services">
      <H1>Our services</H1>
      <Wrapper>
        <Card>
          <Icon src={Icon1} alt={alt1} />
          <H2>Reduce expenses</H2>
          <P>We help reduce your fees and increase your overall revenue.</P>
        </Card>
        <Card>
          <Icon src={Icon2} alt={alt2} />
          <H2>Virtual Offices</H2>
          <P>You can access our platform online anywhere in the world.</P>
        </Card>
        <Card>
          <Icon src={Icon3} alt={alt3} />
          <H2>Premium Benefits</H2>
          <P>Unlock our special membership card that returns 5% cash back.</P>
        </Card>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #010606;
  padding-bottom: 100px;
  /* color: white; */

  @media screen and (max-width: 768px) {
    height: 1100px;
  }
  @media screen and (max-width: 1000px) {
    padding-top: 100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;
const H1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;
const Wrapper = styled.div`
  max-width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;
const Card = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    transform: scale(1.03);
  }
`;
const Icon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
  border-radius: 4px;
  margin-right: 10px;
`;
const H2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;
const P = styled.p`
  font-size: 1rem;
  text-align: center;
`;
export default Services;
